import './DuckIcon.css';

function DuckIcon() {
  return (
    <div className='logoContainer'><svg version="1.1" viewBox="0 0 54.218 51.824" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
    <path d="m54.218 28.164c1e-6 6.5085-3.0343 15.987-7.94 20.252-4.9057 4.2652-11.683 3.3174-19.169 3.3174-7.4859 0-14.242 0.48991-19.169-3.3174-6.0491-4.6748-7.94-13.744-7.94-20.252s2.8471-13.555 7.5982-17.822c4.7511-4.2668 12.025-5.7477 19.511-5.7477 7.4859 0 13.921 1.4825 18.827 5.7477 4.9057 4.2652 8.2818 11.313 8.2818 17.822z" fill="#55d289" strokeWidth="1.0052"/>
    <path d="m13.162 16.334a5.4915 5.5787 0 0 0-5.4922 5.5781 5.4915 5.5787 0 0 0 1.3125 3.6152 5.4915 5.5787 0 0 1 4.1797-1.9648 5.4915 5.5787 0 0 1 4.1797 1.9648 5.4915 5.5787 0 0 0 1.3125-3.6152 5.4915 5.5787 0 0 0-5.4922-5.5781z" fill="#f9f9f9"/>
    <path d="m41.055 16.334a5.4915 5.5787 0 0 0-5.4902 5.5781 5.4915 5.5787 0 0 0 1.3125 3.6152 5.4915 5.5787 0 0 1 4.1777-1.9648 5.4915 5.5787 0 0 1 4.1797 1.9648 5.4915 5.5787 0 0 0 1.3125-3.6152 5.4915 5.5787 0 0 0-5.4922-5.5781z" fill="#f9f9f9"/>
    <path d="m13.614 18.881a1.9177 1.8305 0 0 1-1.9177 1.8305 1.9177 1.8305 0 0 1-1.9177-1.8305 1.9177 1.8305 0 0 1 1.9177-1.8305 1.9177 1.8305 0 0 1 1.9177 1.8305z" fill="#520"/>
    <path d="m45.734 22.238a1.9177 1.8305 0 0 1-1.9177 1.8305 1.9177 1.8305 0 0 1-1.9177-1.8305 1.9177 1.8305 0 0 1 1.9177-1.8305 1.9177 1.8305 0 0 1 1.9177 1.8305z" fill="#520"/>
    <g transform="matrix(1.0677 0 0 1.8492 59.08 -45.278)">
    <path d="m-30.193 36.415c-4.9793 0.10163-9.7461 9.1044-17.788 4.4995 0.89622 0.97357 2.3045 4.4261 4.3988 4.5458-2.4695 0.14111-3.9867 4.3845-4.8331 4.2618-4.0994 0.59408 7.9109-0.56306 7.9109-0.56306l4.3641 1.5389c2.4039 1.0618 10.328 1.0301 12.034 0l4.3641-1.5389s12.01 1.1571 7.9109 0.56306c-0.84637 0.1227-2.3636-4.1207-4.8331-4.2618 2.0944-0.11968 3.501-3.5722 4.3972-4.5458-8.0423 4.6049-12.808-4.3979-17.787-4.4995-0.02296 3.5e-4 -0.04634 0.0039-0.06935 5e-3 -0.02301-0.0011-0.0464-0.0046-0.06935-5e-3zm17.926 4.4995c0.14395-0.08243 0.28985-0.16797 0.43592-0.25924-0.1285-0.01862-0.2755 0.08498-0.43592 0.25924zm-35.714 0c-0.16042-0.17426-0.30577-0.27786-0.43427-0.25924 0.14607 0.09127 0.29032 0.17682 0.43427 0.25924z" fill="#ebce2f" strokeWidth=".84542"/>
    <path d="m-32.225 38.997a0.98426 0.93952 0 0 1-0.98426 0.93952 0.98426 0.93952 0 0 1-0.98426-0.93952 0.98426 0.93952 0 0 1 0.98426-0.93952 0.98426 0.93952 0 0 1 0.98426 0.93952z" fill="#520" strokeWidth=".51326"/>
    <path d="m-26.054 38.997a0.98426 0.93952 0 0 1-0.98426 0.93952 0.98426 0.93952 0 0 1-0.98426-0.93952 0.98426 0.93952 0 0 1 0.98426-0.93952 0.98426 0.93952 0 0 1 0.98426 0.93952z" fill="#520" strokeWidth=".51326"/>
    <path d="m-43.582 45.46c14.933 3.7308 12.208 3.1667 26.918 0" fill="none" stroke="#520" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".39152"/>
    </g>
    <path d="m47.75 14.771a0.98426 0.93952 0 0 1-0.98426 0.93952 0.98426 0.93952 0 0 1-0.98426-0.93952 0.98426 0.93952 0 0 1 0.98426-0.93952 0.98426 0.93952 0 0 1 0.98426 0.93952z" fill="#520" strokeWidth=".51326"/>
    <path d="m42.802 10.497c0-3.6601-2.9911-7.0422-7.8466-8.8723s-10.838-1.8301-15.693 1.2e-6c-4.8555 1.8301-7.8466 5.2122-7.8466 8.8723 16.289-6.3173 15.475-5.1578 31.386 0z" fill="#ebce2f" stroke="#ebce2f" strokeLinecap="round" strokeWidth=".62034"/>
    </g>
    </svg></div>



  )
}

export default DuckIcon; 